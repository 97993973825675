<template>
<div class="main-content contacts">
    <div class="filtered-list-select-section">
        <label>List</label>
        <select v-model="section" @change="getList()">
            <option value="campaigns">Campaings</option>
            <option value="audiences">Audiences</option>
            <option value="segments">Tags</option>
        </select>
    </div>
    <h1>Mailchimp <font-awesome-icon icon="chevron-right" /> {{ localCaptializeWords(section) }}</h1>
    <div class="filtered-list-subhead">
        <font-awesome-icon class="mobile-filters-button" :icon="showFilters ? 'times-circle' : 'plus-circle'" @click="showFilters = !showFilters" />
    </div>
    <div class="filtered-list">
        <div class="filtered-list-sidebar">
            <div v-if="audience_id || campaign_id" class="filtered-list-sidebar-controls">
                <label>Actions...</label>
                <div class="button button-dark" @click="backToList()">Back To List</div>
            </div>
            <div v-else class="filtered-list-sidebar-controls">
                <template v-if="section == 'campaigns'">
                    <label>Filters...</label>
                    <div><input type="checkbox" v-model="filters.sent" @change="getList()"><label>Sent</label></div>
                    <div><input type="checkbox" v-model="filters.not_sent" @change="getList()"><label>Not Sent</label></div>
                    <hr>
                </template>
                <label>Actions...</label>
        		<div v-if="section == 'campaigns'" :class="['button', { disabled: loading }]" @click="updateCampaigns()">Update Campaigns</div>
        		<div v-if="section == 'audiences'" :class="['button', { disabled: loading }]" @click="updateAudiences()">Update Audiences</div>
        		<div class="button button-dark">Update API Key</div>
            </div>
        </div>

        <div v-if="audience_id || campaign_id || group_id"  class="filtered-list-item">
            <div v-if="audience_id" class="filtered-list-item-two-columns">
                <div>
                    <div class="section-heading">Audience Details</div>
                    <label>Name</label>
                    <div>{{ detail.list_name }}</div>
                    <label>Mailchimp ID</label>
                    <div>{{ detail.list_id }}</div>
                    <label>Members</label>
                    <div>{{ detail.members }}</div>
                </div>
                <div>
                    <div class="section-heading">Campaigns</div>
                    <div v-for="(campaign, index) in detail_list" @click="getCampaignRoute(campaign.mailchimp_campaign_id)">
                        {{ campaign.campaign_name || campaign.subject }}
                    </div>
                </div>
            </div>
            <div v-if="campaign_id" class="filtered-list-item-two-columns">
                <div>
                    <div class="section-heading">Campaign Details</div>
                    <label>Created Time</label>
                    <div>{{ detail.create_time }}</div>
                    <label>Name</label>
                    <div>{{ detail.campaign_name || detail.subject }}</div>
                    <label>Mailchimp ID</label>
                    <div>{{ detail.campaign_id }}</div>
                    <label>Report Updated</label>
                    <div>{{ detail.report_time }}</div>
                </div>
                <div>
                    <div class="section-heading">Report</div>
                    <label>Sent Time</label>
                    <div>{{ detail.campaign_report.send_time }}</div>
                    <label>Emails Sent</label>
                    <div>{{ detail.campaign_report.emails_sent }}</div>
                    <label>Opens</label>
                    <div>{{ detail.campaign_report.report_summary.opens }}</div>
                    <label>Unique Opens</label>
                    <div>{{ detail.campaign_report.report_summary.unique_opens }}</div>
                    <label>Clicked</label>
                    <div>{{ detail.campaign_report.report_summary.clicks }}</div>
                </div>
            </div>
        </div>

        <div v-else class="filtered-list-content">
            <div v-if="section == 'audiences'">
                <table class="filtered-list-table">
                    <tr class="clickable-list-header">
                        <th>Description</th>
                        <th>Members</th>
                    </tr>
                    <tr v-for="(audience, index) in audiences" class="clickable-list" @click="getAudienceRoute(audience.mailchimp_audience_id, audience.mailchimp_group_id)">
                        <td>{{ audience.group_name ? '&mdash;&nbsp;' + audience.group_name : audience.list_name }}</td>
                        <td>{{ audience.members }}</td>
                    </tr>
                </table>
            </div>
            <div v-if="section == 'campaigns'">
                <table class="filtered-list-table">
                    <tr class="clickable-list-header">
                        <th>Description</th>
                        <th>Created</th>
                        <th>Sent</th>
                        <th>Opens</th>
                        <th>Clicks</th>
                    </tr>
                    <tr v-for="(campaign, index) in campaigns" class="clickable-list" @click="getCampaignRoute(campaign.mailchimp_campaign_id)">
                        <td>{{ campaign.campaign_name || campaign.subject }}</td>
                        <td>{{ campaign.create_date }}</td>
                        <td>{{ campaign.send_date }}</td>
                        <td>{{ campaign.send_date ? campaign.opens : '' }}</td>
                        <td>{{ campaign.send_date ? campaign.clicks : '' }}</td>
                    </tr>
                </table>
            </div>
            <div v-if="section == 'segments'">
                <table class="filtered-list-table">
                    <tr class="clickable-list-header">
                        <th>Description</th>
                        <th>Members</th>
                    </tr>
                    <tr v-for="(segment, index) in segments" class="clickable-list" @click="getSegmentRoute(segment.mailchimp_segment_id)">
                        <td>{{ segment.segment_name }}</td>
                        <td>{{ segment.members }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <MessageModal v-if="message" :message="message" :loading="loading" :errorStatus="errorStatus" @dismiss="message = ''" />

    <iframe id="download" src="" style="display: none;"></iframe>

</div>
</template>
<script>
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import MessageModal from '@/components/MessageModal.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
    name: 'Login',
    components: {
        LabelWithLoadingIcon,
        MessageModal
    },
    data() {
        return {
            loading: false,
            message: '',
            errorStatus: false,
            filters: {
                sent: false,
                not_sent: false
            },
            filterMessage: '',
            section: 'campaigns',
            audiences: [],
            audience_id: '',
            campaigns: [],
            campaign_id: '',
            group_id: '',
            segments: [],
            segment_id: '',
            detail: {},
            detail_list: [],
            saving: false,
            saved: false,
            showFilters: false
        }
    },
    created() {
        this.audience_id = this.$route.params.audience_id
        this.group_id = this.$route.params.campaign_id
        this.campaign_id = this.$route.params.campaign_id
        if (this.group_id) {

        } else if (this.audience_id) {

        } else if (this.campaign_id) {

        } else {
            this.getList()
        }
        this.showFilters = window.matchMedia("(min-width: 768px)").matches
    },
    watch: {
        '$route.params': function (params) {
            this.campaign_id = ''
            this.audience_id = ''
            this.group_id = ''
            this.detail = {}
            this.detail_list = []
            if (params.group_id) {
                this.getGroup(params.group_id)
            } else if (params.audience_id) {
                this.getAudience(params.audience_id)
            } else if (params.campaign_id) {
                this.getCampaign(params.campaign_id)
            } else {
                this.getList()
            }
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getUser',
            'getDownloadEndpoint'
        ])
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        },
        getList() {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_list_mailchimp_' + this.section, data: this.filters }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    if (this.section == 'campaigns') {
                        this.campaigns = obj.campaigns
                    } else if (this.section == 'segments') {
                        this.segments = obj.segments
                    } else {
                        this.audiences = obj.audiences
                    }
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        setSection(which) {
            this.section = which
            this.getList()
        },
        updateAudiences() {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'update_mailchimp_audiences' }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.getList()
                    this.message = 'Audiences have been updated!'
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        updateCampaigns() {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'update_mailchimp_campaigns' }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.getList()
                    this.message = 'Campaign list has been updated!'
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        getAudienceRoute(audienceId, groupId) {
            if (groupId) {
                this.$router.push('/mailchimp/audience/' + audienceId + '/group/' + groupId)
            } else {
                this.$router.push('/mailchimp/audience/' + audienceId)
            }
        },
        getCampaignRoute(campaignId) {
            this.$router.push('/mailchimp/campaign/' + campaignId)
        },
        getGroup(groupId) {

        },
        getAudience(audienceId) {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_item_mailchimp_audience', data: { mailchimp_audience_id: audienceId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.audience_id = audienceId
                    this.detail = obj.audience
                    this.detail_list = obj.campaigns
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        getCampaign(campaignId) {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_item_mailchimp_campaign', data: { mailchimp_campaign_id: campaignId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.campaign_id = campaignId
                    this.detail = obj.campaign
                    this.detail_list = []
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        backToList() {
            this.$router.push('/mailchimp')
        }
    }
}
</script>
